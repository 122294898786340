/* eslint-disable no-return-assign */
import { createSlice } from '@reduxjs/toolkit';

export const reportSlice = createSlice({
  name: 'settings',
  initialState: {
    loading: 'idle',
    version: 1,
    showFormHelper: true,
  },
  reducers: {
    disableFormHelper: state => {
      state.showFormHelper = false;
    },
  },
});

export const selectShowFormHelper = ({ settings }) => settings.showFormHelper;

export const { disableFormHelper } = reportSlice.actions;

export default reportSlice.reducer;
