import miners from './miners.json';
import intWithSeparator from './intWithSeparator';

const UNITS = [
  'Hashes',
  'Kilohashes',
  'Megahashes',
  'Gigahashes',
  'Terahashes',
  'Petahashes',
  'Exahashes',
  'Zettahashes',
  'Yottahashes',
  'Brontohashes',
];

/*
  Returns miner information as string in format:
  Antminer S9 (14.5 TH/s, 1365 W, 94.14 J/TH)
*/
const hashPowerMinerInfo = network => {
  const miner = miners[network][0];
  const {
    name,
    hashRate,
    hashRateMeasure,
    powerEfficiency,
    powerEfficiencyMeasure,
    powerConsumption,
    powerConsumptionMeasure,
  } = miner;
  return `${name} (${hashRate} ${hashRateMeasure}, ${powerConsumption} ${powerConsumptionMeasure}, ${powerEfficiency} ${powerEfficiencyMeasure})`;
};

const securedValue = (network, rawTotal, format = true) => {
  const electricityCost = 0.04;

  const miner = miners[network][0];
  let { unit, powerEfficiency, powerConsumption } = miner; // eslint-disable-line

  if (unit === 'MH') {
    powerEfficiency /= 1000000;
    powerConsumption /= 1000000;
  }

  const kilowattsPerHourPerTh = powerEfficiency / (60 * 60) / powerConsumption;
  const costPerTh = kilowattsPerHourPerTh * electricityCost;
  const hashRateTh = rawTotal / 1000000000000;

  let value = hashRateTh * costPerTh;
  if (unit === 'MH') value *= 1000000;

  return format ? intWithSeparator(value, { maxDecimals: 2, style: 'currency' }) : value;
};

const convertHash = hashPower => {
  const combinedHashPower = hashPower.reduce((acc, { rawTotal }) => acc + rawTotal, 0);
  let order = 0;
  let modifiedHashes = combinedHashPower;
  while (Math.abs(modifiedHashes) > 100000 && order < UNITS.length - 1) {
    modifiedHashes /= 1000;
    order += 1;
  }
  return { value: intWithSeparator(modifiedHashes, { maxDecimals: 2 }), units: UNITS[order] };
};

/*
  Returns USD value of combined hashPowers via the hashPowerToUSD() method
*/
const totalSecuredValue = (hashPower, format = true) => {
  if (!hashPower || hashPower.length === 0) return null;

  const value = hashPower.reduce(
    (acc, { network, rawTotal }) => acc + securedValue(network, rawTotal, false),
    0,
  );
  return format ? intWithSeparator(value, { maxDecimals: 2, style: 'currency' }) : value;
};

/*

  # hashPowerToUSD()

  Returns hashPower converted to USD via a set electricity cost and set miner rig (either ASIC/BTC or GPU/ETH)

  # Calculations
  Based on information found at:
  
  https://medium.com/luxor/energy-consumption-kwh-to-hashrate-ph-s-guide-for-bitcoin-mining-b0c02872c089
  https://eternal.dragonchain.com/transaction/d12b073b-657a-4e15-ac95-dde37d61ccd4

  # Metrics
  https://metrics-staging.api.dragonchain.com/v1/hashes?network=BTC&since=1580688000
  https://metrics-staging.api.dragonchain.com/v1/hashes?network=ETH&since=1580688000

  # Params
  network {string}, ex. BTC, ETH
  rawTotal {hashes}, ex. 1.115834610453279e+26

  # Set the cost of electricity per kWH ($0.04)
  {electricityCost}

  # Miners
  Miner data is in miners.json

  BTC miners use terrahash units of measure (TH)
  ETH miners use megahash units of measure (MH)

  GPU miners (for ETH mining) tend to be missing the powerEfficiency values when researching
  To determine the powerEfficiency, it can be calculated using the hashRate and powerConsumption (W)

  {powerEfficiency} J/MH = {powerConsumption} W / {hashRate} MH/s

  # Choose a Miner (ie. Antminer S9), grab the hashRate,  powerEfficiency and the powerConsumption
  miner {json}, ex. BTC, ETH

  # Units

  https://coinguides.org/hashpower-converter-calculator/

  The conversion is calculated in terrahashes (TH)
  For miners using Megahash units, the hashRate, powerEfficiency, and powerConsumption are converted to TH
  Once the value is converted, it is normalized back to reflect the value as it related to megahashes (MH)

  # Calculation
  1. Determine the miner's kWH/TH
  {kilowattsPerHourPerTh} kWh / TH = ({powerEfficiency} Joules / (60 seconds x 60 minutes)) / ({powerConsumption} Watts/kW)

  2. Determine the cost per TH
  {costPerTh} = {kilowattsPerHourPerTh} * {electricityCost}

  3. Convert the rawTotal hashrate from hashes to terahashes (TH)
  {hashrateTh} = {rawTotal} / 1000000000000

  4. Value
  {value} = {hashrateTh} * {costPerTh};

  # Proof
  First determine the monthly cost to run the miner
  {monthlyCostOfRunningMiner} = ({electricityCost} USD per kWh) x (24 hours/day) x (30.42 days/month) x ({powerConsumption} kWH)

  2. Then multiply the calculated costPerTh of the miner (TH/s) multiplied by seconds in a month
  {monthlyCostFromCostPerTh} = {costPerTh} x (({hashRate}) x (60 x 60 x 24 x 30.42) x ({powerConsumption} kWH)

  3. These two numbers should be very close to one another in dollars

  # Notes:

  Ethereum mining
  https://www.genesis-mining.com/ethereum
  $29 per MH/s

  # Mining Costs

  # Bitcoin

  Calculator:
  https://www.buybitcoinworldwide.com/mining/calculator/

  Miner:
  Antminer S9
  14.5 TH/s, 94.14 J/TH, 1365 W

  Monthly cost $39.86 (calculated by Hashpower.js)
  Monthly cost $39.31 (calculated by the above calculator)

  # Ethereum

  Calculator:
  https://www.cryptocompare.com/mining/calculator/eth?HashingPower=90&HashingUnit=MH%2Fs&PowerConsumption=319&CostPerkWh=0.04&MiningPoolFee=0

  Miner:
  AMD Radeon VII
  90 MH/s, 3.54 J/MH, 319 W

  Monthly cost $9.31 (calculated by Hashpower.js)
  Monthly cost $9.19 (determined by the above calculator)



  // # Proof
  const costPerMh = costPerTh * 1000000;
  let monthlyCostOfRunningMiner;
  let monthlyCostFromCostPerUnit;

  if (unit === "MH") {
    monthlyCostOfRunningMiner =
      electricityCost * 24 * 30.42 * (powerConsumption / 1000) * 1000000;
    monthlyCostFromCostPerUnit =
      costPerMh *
      hashRate *
      (60 * 60 * 24 * 30.42) *
      (powerConsumption / 1000) *
      1000000;
  } else {
    monthlyCostOfRunningMiner =
      electricityCost * 24 * 30.42 * (powerConsumption / 1000);
    monthlyCostFromCostPerUnit =
      costPerTh * hashRate * (60 * 60 * 24 * 30.42) * (powerConsumption / 1000);
  }
  // # End Proof

  console.log("---");
  console.log("hashpowerToUSD()");
  console.log("name", miner.name);
  console.log("hashRate", hashRate);
  console.log("powerEfficiency", powerEfficiency);
  console.log("powerConsumption", powerConsumption);
  console.log("kilowattsPerHourPerTh", kilowattsPerHourPerTh);
  console.log("costPerTh", costPerTh);
  console.log("hashrateTh", hashrateTh);
  console.log("value", value);
  console.log("Proof (should be relatively close values)");
  console.log("monthlyCostOfRunningMiner", monthlyCostOfRunningMiner);
  console.log("monthlyCostFromCostPerUnit", monthlyCostFromCostPerUnit);


*/

export { hashPowerMinerInfo, securedValue, totalSecuredValue, convertHash };
