import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import throttle from 'lodash.throttle';
import reportReducer from './reportSlice';
import settingsReducer from './settingsSlice';
import errorReducer from './errorSlice';
import { loadState, saveState } from '../utils/localStorage';
import { dragonchainReportSchema } from './globals';

const STATE_KEY = 'eternal';
const preloadedState = loadState(STATE_KEY);

const store = configureStore({
  reducer: {
    report: reportReducer,
    settings: settingsReducer,
    error: errorReducer,
  },
  preloadedState,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

store.subscribe(
  throttle(() => {
    saveState(STATE_KEY, {
      settings: store.getState().settings,
      report: {
        myArchive: store.getState().report.myArchive,
        transactionId: null,
        ...dragonchainReportSchema,
      },
    });
  }, 1000),
);

export default store;
