import { createSlice } from '@reduxjs/toolkit';

const reset = state => {
  state.message = null;
  state.status = null;
  state.id = null;
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    message: null,
    status: null,
    id: null,
  },
  reducers: {
    getError: (state, action) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.id = action.payload.id;
    },
    clearError: state => reset(state),
  },
  extraReducers: {},
});

export const { getError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
