export const validHashPowerNetworks = ['ETH', 'BTC'];

export const dragonchainReportSchema = {
  summary: {
    appliedTime: null,
    timestamp: null,
    transactionType: null,
    transactionId: null,
    securedBy: {
      networks: [],
      value: null,
    },
    hashPower: {
      combined: {
        value: null,
        units: '',
      },
      networks: [],
    },
    verifications: [],
  },
  transaction: {
    version: '2',
    dcrn: 'Transaction::L1::FullTransaction',
    header: {
      txn_type: null,
      dc_id: null,
      txn_id: null,
      block_id: null,
      timestamp: null,
      tag: '',
      invoker: '',
    },
    payload: {
      symbol: '',
      price: '',
      size: '',
      side: '',
      beaxyTimestamp: null,
      autoId: null,
    },
    proof: {
      full: null,
      stripped: null,
    },
  },
  block: {
    version: '1',
    dcrn: 'Block::L1::AtRest',
    header: {
      dc_id: null,
      block_id: null,
      level: 1,
      timestamp: null,
      prev_id: null,
      prev_proof: null,
    },
    transactions: [],
    proof: {
      scheme: 'trust',
      proof: null,
    },
  },
  verifications: {
    L2: [
      {
        version: '1',
        dcrn: 'Block::L2::AtRest',
        header: {
          dc_id: null,
          current_ddss: null,
          level: 2,
          block_id: null,
          timestamp: null,
          prev_proof: null,
        },
        validation: {
          dc_id: null,
          block_id: null,
          stripped_proof: null,
          transactions: null,
        },
        proof: {
          scheme: 'trust',
          proof: null,
        },
      },
    ],
    L3: [
      {
        version: '2',
        dcrn: 'Block::L3::AtRest',
        header: {
          dc_id: null,
          current_ddss: null,
          level: null,
          block_id: null,
          timestamp: null,
          prev_proof: null,
        },
        'l2-validations': {
          l1_dc_id: null,
          l1_block_id: null,
          l1_proof: null,
          l2_proofs: [
            {
              dc_id: null,
              block_id: null,
              proof: null,
            },
          ],
          ddss: null,
          count: null,
          regions: [],
          clouds: [],
        },
        proof: {
          scheme: 'trust',
          proof: null,
        },
      },
    ],
    L4: [
      {
        version: '3',
        dcrn: 'Block::L4::AtRest',
        header: {
          chain_name: null,
          dc_id: null,
          current_ddss: null,
          level: 4,
          block_id: null,
          timestamp: null,
          l1_dc_id: null,
          l1_block_id: null,
          l1_proof: null,
          prev_proof: null,
        },
        'l3-validations': [
          {
            l3_dc_id: null,
            l3_block_id: null,
            l3_proof: null,
            valid: false,
          },
        ],
        proof: {
          scheme: 'trust',
          proof: null,
        },
      },
    ],
    L5: [
      {
        version: '1',
        dcrn: 'Block::L5::AtRest',
        header: {
          dc_id: null,
          current_ddss: null,
          level: 5,
          block_id: null,
          timestamp: null,
          prev_proof: null,
        },
        'l4-blocks': [],
        proof: {
          scheme: 'trust',
          transaction_hash: [],
          block_last_sent_at: null,
          network: null,
          proof: null,
        },
      },
    ],
  },
};

export const l5DragonchainIds = () => {
  return {
    // DEVELOPMENT CHAINS
    '2AQapXfhQCrD1DdyW2sbf7XmDZR1AQK9Jik5wxPPUTqob': 'BNB',
    zCNJbb4DwPdb9U9MQhxh5jWuVbd7N56X8ApN6hUQrLCx: 'ETC',
    vAo8DkPEPS8syjxEoJRmNNoX3tt6upXkSqhGxwzjz5Ws: 'ETH',
    x9RLJC7pZW1QLCsQAWYsBLSKMXG7sAVNg9Bo9BJxDuB5: 'BTC',
    // PRODUCTION CHAINS
    yKi2h5qdJYA3hHjGMuZBRVzMLXJT7bzvi5xHv4TCDaBC: 'BNB',
    nLEfr5AeBBTwQ71iKbdv2G5wokoDBMD88bwtX6hW8Y71: 'ETC',
    qG7E9B5fteM74dgWN2ik9fJPcRzrYWwZSX5A7YVrYVbk: 'ETH',
    h1C9cwP6KXYxLFktsyFBjxpcdgWGTm4nvMXiUt27gTLk: 'BTC',
    wZtWzuU97GJDQh3LdDVjfoHXyDv6DMkEJ1VkPzQny6JU: 'ETH',
    i7DvBgJog7hbsdAiNeZZ588QCghRLYqU3YFuq9FHZWHy: 'BSC',
  };
};

export const explorers = () =>
  process.env.GATSBY_ACTIVE_ENV === 'production'
    ? {
        ETH: 'https://etherscan.io/tx',
        ETC: 'https://etcblockexplorer.com/tx',
        BTC: 'https://www.blockchain.com/btc/tx',
        NEO: 'https://neotracker.io/tx',
        BNB: 'https://explorer.binance.org/tx',
        BSC: 'https://bscscan.com/tx/',
      }
    : {
        ETH: 'https://ropsten.etherscan.io/tx',
        ETC: 'https://etcblockexplorer.com/tx',
        BTC: 'https://live.blockcypher.com/btc-testnet/tx',
        NEO: 'https://neoscan-testnet.io/transaction',
        BNB: 'https://testnet-explorer.binance.org/tx',
        BSC: 'https://testnet.bscscan.com/tx/',
      };
