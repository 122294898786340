/* eslint-disable no-return-assign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { dragonchainReportSchema, explorers, l5DragonchainIds, validHashPowerNetworks } from './globals';
import { convertHash, totalSecuredValue } from '../utils/hashPower';

const sum = (a, v) => a + v;

// type: ['tweet', 'url', 'text']
export const postTransaction = createAsyncThunk('eternal/transaction', async ({ type, url, text }) => {
  if (!!url && !url.includes('http')) url = `https://${url}`;
  const transaction = await Axios.post(`${process.env.ETERNAL_API}/v1/transaction/${type}`, { url, text });
  return transaction.data.transaction_id;
});

export const getReport = createAsyncThunk('report/transaction', async ({ transactionId }) => {
  const report = await Axios.get(`${process.env.ETERNAL_API}/v1/report/${transactionId}`);
  return report;
});

export const getHashPower = createAsyncThunk('report/hashPower', async ({ networks }) => {
  const hashPower = await Promise.all(
    networks.map(async b => {
      if (!validHashPowerNetworks.includes(b.network)) return false;
      return (
        await Axios.get(`${process.env.METRICS_API}/v1/hashes?network=${b.network}&since=${b.timestamp}`)
      ).data;
    }),
  );
  return hashPower.filter(h => !!h);
});

const createNetworkLink = (network, l5) => {
  if (l5DragonchainIds()[l5.header.dc_id] === 'BTC') {
    // BTC needs '0x' stripped
    return `${explorers()[network]}/${l5.proof.transaction_hash[0].substring(2)}`;
  }
  return `${explorers()[network]}/${l5.proof.transaction_hash[0]}`;
};

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    loading: 'idle',
    version: 1,
    transactionId: null,
    myArchive: [],
    ...dragonchainReportSchema,
  },
  reducers: {
    clearReport: state => {
      state.transaction = dragonchainReportSchema.transaction;
      state.block = dragonchainReportSchema.block;
      state.verifications = dragonchainReportSchema.verifications;
      state.summary = dragonchainReportSchema.summary;
      state.loading = 'idle';
    },
    clearTransactionId: state => {
      state.transactionId = null;
    },
  },

  extraReducers: {
    [postTransaction.pending]: state => {
      state.loading = 'pending';
    },
    [postTransaction.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.transactionId = action.payload;
        state.myArchive.push(action.payload);
        state.loading = 'idle';
      }
    },
    [postTransaction.rejected]: state => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },

    [getReport.pending]: state => {
      state.loading = 'pending';
      state.transaction = dragonchainReportSchema.transaction;
      state.block = dragonchainReportSchema.block;
      state.verifications = dragonchainReportSchema.verifications;
      state.summary = dragonchainReportSchema.summary;
    },
    [getReport.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        const report = action.payload;
        const {
          l1Transaction,
          l1Block,
          l2Verifications,
          l3Verifications,
          l4Verifications,
          l5Verifications,
        } = report.data;

        // fix missing hex value in transactionHash
        const nL5Verifications =
          l5Verifications &&
          l5Verifications.map(l5 => {
            const { proof } = l5;
            const transactionHash = proof.transaction_hash.map(t => {
              return t.substring(0, 2) === '0x' ? t : `0x${t}`;
            });
            return { ...l5, proof: { ...proof, transaction_hash: transactionHash } };
          });

        const { timestamp, txn_type: transactionType, txn_id: transactionId } = l1Transaction.header;
        const l1BlockTimestamp = l1Block.header.timestamp;
        const l2BlockTimestamps = l2Verifications && l2Verifications.map(l2 => l2.header.timestamp);
        const l3BlockTimestamps = l3Verifications && l3Verifications.map(l3 => l3.header.timestamp);
        const l4BlockTimestamps = l4Verifications && l4Verifications.map(l4 => l4.header.timestamp);
        const l5BlockTimestamps = nL5Verifications && nL5Verifications.map(l5 => l5.header.timestamp);

        const appliedTime = [
          l2Verifications && l2Verifications.map(l2 => Number(l2.header.current_ddss)).reduce(sum, 0),
          l3Verifications && l3Verifications.map(l3 => Number(l3.header.current_ddss)).reduce(sum, 0),
          l4Verifications && l4Verifications.map(l4 => Number(l4.header.current_ddss)).reduce(sum, 0),
          nL5Verifications && nL5Verifications.map(l5 => Number(l5.header.current_ddss)).reduce(sum, 0),
        ].reduce(sum, 0);

        const securedByNetworks = nL5Verifications.map(l5 => {
          const network = l5DragonchainIds()[l5.header.dc_id];
          const url = createNetworkLink(network, l5);
          return { blockId: l5.header.block_id, timestamp: l5.header.timestamp, network, url };
        });

        const summary = {
          ...state.summary,
          appliedTime,
          timestamp,
          transactionType,
          transactionId,
          securedBy: {
            ...state.summary.securedBy,
            networks: securedByNetworks,
          },
          verifications: [
            { L1: l1BlockTimestamp ? 1 : 0 },
            { L2: l2BlockTimestamps.length },
            { L3: l3BlockTimestamps.length },
            { L4: l4BlockTimestamps.length },
            { L5: l5BlockTimestamps.length },
          ],
        };
        l1Block.transactions = l1Block.transactions.map(t => JSON.parse(t));
        l2Verifications.map(l2 => (l2.validation.transactions = JSON.parse(l2.validation.transactions)));
        nL5Verifications.map(l5 => (l5['l4-blocks'] = l5['l4-blocks'].map(b => JSON.parse(b))));

        state.transaction = l1Transaction;
        state.block = l1Block;
        state.verifications = {
          L2: l2Verifications,
          L3: l3Verifications,
          L4: l4Verifications,
          L5: nL5Verifications,
        };
        state.summary = summary;
        state.loading = 'idle';
      }
    },
    [getReport.rejected]: state => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },

    [getHashPower.pending]: state => {
      state.loading = 'pending';
    },
    [getHashPower.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        const hashPower = action.payload;

        state.summary.hashPower = { combined: convertHash(hashPower), networks: hashPower };
        state.summary.securedBy.value = totalSecuredValue(hashPower, true);
      }
    },
    [getHashPower.rejected]: state => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },
  },
});

export const selectTransactionId = ({ report }) => report.transactionId;
export const selectTransactionType = ({ report }) => report.transaction.header.txn_type;
export const selectTransactionTimestamp = ({ report }) => report.transaction.header.timestamp;
export const selectSummary = ({ report }) => report.summary;
export const selectTransaction = ({ report }) => report.transaction;
export const selectBlock = ({ report }) => report.block;
export const selectVerifications = ({ report }) => report.verifications;
export const selectDownload = ({ report }) => {
  return {
    summary: report.summary,
    transaction: report.transaction,
    block: report.block,
    verifications: report.verifications,
  };
};

export const { clearReport, clearTransactionId } = reportSlice.actions;

export default reportSlice.reducer;
