export default (str, { maxDecimals, minDecimals, style } = {}) => {
  if (str === '') return str;
  const formattedNumber = new Intl.NumberFormat('en', {
    maximumFractionDigits: maxDecimals || 2,
    minimumFractionDigits: minDecimals || 0,
    style,
    currency: style && 'USD',
  }).format(str);
  if (formattedNumber === 'NaN') return str;
  return formattedNumber;
};
