const LATEST_VERSION = 1;

export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return undefined;

    const state = JSON.parse(serializedState);
    if (state.version !== LATEST_VERSION) return undefined;
    delete state.version;
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const versionedState = { ...state, version: LATEST_VERSION };
    const serializedState = JSON.stringify(versionedState);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};
